import {useCallback, useEffect, useMemo, useState} from 'react'
import {DropdownOptionProps} from 'wix-ui-tpa/cssVars'
import {useBi, useEnvironment, useTranslation} from '@wix/yoshi-flow-editor'
import {zonedTimeToUtc} from '@wix/table-reservations-lib/timezone'
import {
  ApprovalMode,
  ReservationLocation,
} from '@wix/ambassador-table-reservations-v1-reservation-location/types'
import {setHMToDate} from '@wix/table-reservations-lib/schedule'
import {Status} from '@wix/ambassador-table-reservations-v1-time-slot/types'

import {
  getInitialReservationLocation,
  getReservationLocationById,
  getReservationLocationsOptions,
} from '../../../../../utils/reservationLocation'
import {
  getInitialPartySizeFromOptions,
  getPartySizeOptionsForReservationLocation,
} from '../../../../../utils/partySize'
import {filterDate, getNearestWorkingDate} from '../../../../../utils/date'
import {getBusinessScheduleFromReservationLocation} from '../../../../../utils/businessSchedule'
import {findTimeOption, getTimeOptions, TimeOption} from '../../../../../utils/timeOptions'
import {useReservationAddonStorage} from '../../../storage'
import {FieldName, getLogger} from '../../../../../utils/getLogger'
import {useElementWidth} from '../../../../../utils/useElementWidth'
import {getReservationAddOnDataHookWithId} from '../../constants'

export const useHooks = (enabledReservationLocations: ReservationLocation[]) => {
  const {
    goToReservationsPage,
    goToDetailsPage,
    regionalSettings,
    compId,
    isSiteBusinessPremium,
    getDayTimeSlots,
    dayTimeSlots,
  } = useReservationAddonStorage()

  // reservations unavailable
  const [shouldShowReservationsUnavailable, setShouldShowReservationsUnavailable] = useState(false)

  const handleHideReservationsUnavailable = useCallback(() => {
    setShouldShowReservationsUnavailable(false)
  }, [])

  const {isPreview, isSSR} = useEnvironment()

  const bi = useBi()
  const logger = getLogger(bi)

  const {t} = useTranslation()
  const {isMobile} = useEnvironment()

  const reservationAddOnDataHookWithId = useMemo(
    () => getReservationAddOnDataHookWithId(compId),
    [compId],
  )

  const {width: submitButtonContentWidth, readAndSetWidth: readAndSetSubmitButtonContentWidth} =
    useElementWidth<HTMLButtonElement>(reservationAddOnDataHookWithId)
  const [isLoading, setIsLoading] = useState(false)

  const [selectedReservationLocation, setSelectedReservationLocation] = useState<
    ReservationLocation | undefined
  >(getInitialReservationLocation(enabledReservationLocations))

  useEffect(() => {
    setSelectedReservationLocation(getInitialReservationLocation(enabledReservationLocations))
  }, [enabledReservationLocations])

  const partySizeOptions = useMemo(
    () => getPartySizeOptionsForReservationLocation(t, selectedReservationLocation),
    [selectedReservationLocation],
  )

  const [selectedPartySize, setSelectedPartySize] = useState(
    getInitialPartySizeFromOptions(partySizeOptions),
  )

  useEffect(() => {
    setSelectedPartySize(getInitialPartySizeFromOptions(partySizeOptions))
  }, [partySizeOptions])

  const [isManualApproval, setIsManualApproval] = useState(false)

  useEffect(() => {
    const manualApprovalConfig =
      selectedReservationLocation?.configuration?.onlineReservations?.approval

    const partySizeThreshold =
      manualApprovalConfig?.manualForLargePartiesOptions?.partySizeThreshold || 0

    const shouldBeManualApprove =
      manualApprovalConfig?.mode === ApprovalMode.MANUAL ||
      (manualApprovalConfig?.mode === ApprovalMode.MANUAL_FOR_LARGE_PARTIES &&
        selectedPartySize >= partySizeThreshold)

    setIsManualApproval(shouldBeManualApprove)
  }, [selectedPartySize, selectedReservationLocation])

  const reservationLocationsOptions: DropdownOptionProps[] = useMemo(
    () => getReservationLocationsOptions(enabledReservationLocations, t),
    [enabledReservationLocations],
  )

  const businessSchedule = useMemo(
    () => getBusinessScheduleFromReservationLocation(selectedReservationLocation),
    [selectedReservationLocation],
  )

  // date
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(() =>
    getNearestWorkingDate({
      businessSchedule,
      regionalSettings,
      timeZone: selectedReservationLocation?.location?.timeZone,
    }),
  )

  // time
  const timeOptions = useMemo(
    () => getTimeOptions({timeSlots: dayTimeSlots, regionalSettings}),
    [dayTimeSlots, regionalSettings],
  )
  const [selectedTime, setSelectedTime] = useState<TimeOption | undefined>(() =>
    selectedDate ? findTimeOption(timeOptions, selectedDate) : undefined,
  )

  const clickOnReservationsAttributesBiParams = useMemo(
    () => ({
      isAddOn: true,
      isMultiLocation: !!enabledReservationLocations.length,
      isPreview,
      locationId: selectedReservationLocation?.location?.id,
      reservationLocationId: selectedReservationLocation?.id,
    }),
    [enabledReservationLocations.length, isPreview, selectedReservationLocation?.id],
  )

  const handleFilterDate = useCallback(
    (date: Date) =>
      filterDate({
        date,
        businessSchedule,
        timeZone: selectedReservationLocation?.location?.timeZone,
        regionalFormat: regionalSettings,
      }),
    [businessSchedule, selectedReservationLocation?.location?.timeZone, regionalSettings],
  )

  const handleLocationChange = (option: DropdownOptionProps) => {
    const newSelectedReservationLocation =
      getReservationLocationById(option.id!, enabledReservationLocations) ??
      enabledReservationLocations[0]

    logger.clickOnReservationsAttributes({
      ...clickOnReservationsAttributesBiParams,
      locationId: newSelectedReservationLocation?.location?.id,
      reservationLocationId: newSelectedReservationLocation?.id,
      fieldName: FieldName.location,
    })

    setSelectedReservationLocation(newSelectedReservationLocation)
  }

  const handlePartySizeChange = (option: DropdownOptionProps) => {
    logger.clickOnReservationsAttributes({
      ...clickOnReservationsAttributesBiParams,
      fieldName: FieldName.partySize,
    })
    setSelectedPartySize(Number(option.id))
  }

  const handleDateChange = (date: Date) => {
    logger.clickOnReservationsAttributes({
      ...clickOnReservationsAttributesBiParams,
      fieldName: FieldName.date,
    })

    setSelectedDate(date)
    setSelectedTime(undefined)

    loadDayTimeSlots(date)
  }

  const handleTimeChange = (option: TimeOption) => {
    logger.clickOnReservationsAttributes({
      ...clickOnReservationsAttributesBiParams,
      fieldName: FieldName.time,
    })

    setSelectedTime(option)
  }

  const handleFindTableSubmit = async (e) => {
    e.preventDefault()

    const isPartySizeExceedsMax = selectedPartySize >= partySizeOptions.length

    readAndSetSubmitButtonContentWidth()

    if (!selectedReservationLocation?.id || !selectedDate || !selectedTime) {
      return null
    }

    const selectedDateAndTime = setHMToDate(
      selectedDate,
      selectedTime.data.hours,
      selectedTime.data.minutes,
    )

    logger.clickOnFindATable({
      datetime: selectedDateAndTime,
      isMultiLocation: !!reservationLocationsOptions.length,
      locationId: selectedReservationLocation?.location?.id,
      reservationLocationId: selectedReservationLocation?.id,
      isPreview,
      partySize: selectedPartySize,
    })

    if (
      !selectedReservationLocation.configuration?.onlineReservations?.onlineReservationsEnabled ||
      !isSiteBusinessPremium
    ) {
      setShouldShowReservationsUnavailable(true)
      return
    }

    setIsLoading(true)

    const params = {
      reservationData: {
        startDate: zonedTimeToUtc(
          selectedDateAndTime,
          selectedReservationLocation?.location?.timeZone,
        ),
        partySize: selectedPartySize,
        reservationLocationId: selectedReservationLocation.id!,
      },
    }

    if (isManualApproval && !isPartySizeExceedsMax) {
      await goToDetailsPage(params)
    } else {
      await goToReservationsPage(params)
    }
  }

  const loadDayTimeSlots = (date?: Date) => {
    if (!selectedReservationLocation?.id || !date) {
      return
    }

    getDayTimeSlots({
      reservationLocationId: selectedReservationLocation.id,
      date,
      partySize: selectedPartySize,
      timeSlotInterval:
        selectedReservationLocation.configuration?.onlineReservations?.timeSlotInterval,
      timeZone: selectedReservationLocation?.location?.timeZone,
    })
  }

  useEffect(() => {
    loadDayTimeSlots(selectedDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReservationLocation])

  useEffect(() => {
    const newSelectedTime =
      timeOptions.find(({data}) => data.status === Status.AVAILABLE) ?? timeOptions[0]

    if (newSelectedTime) {
      setSelectedTime(newSelectedTime)
    }
  }, [timeOptions])

  return {
    t,
    isSSR,
    submitButtonContentWidth,
    isMobile,
    regionalSettings,
    reservationLocationsOptions,
    isLoading,
    partySizeOptions,
    timeOptions,
    selectedReservationLocation,
    selectedPartySize,
    selectedDate,
    selectedTime,
    handleLocationChange,
    handlePartySizeChange,
    handleDateChange,
    handleTimeChange,
    handleFindTableSubmit,
    filterDate: handleFilterDate,
    shouldShowReservationsUnavailable,
    handleHideReservationsUnavailable,
    reservationAddOnDataHookWithId,
  }
}
