import {ReservationLocation} from '@wix/ambassador-table-reservations-v1-reservation-location/types'
import {useMemo} from 'react'

import {getIsLocationCompletelyManual} from './reservationLocation'
import {getShouldShowAutoApproveText} from './getShouldShowAutoApproveText'
import {ApprovalTextEditorState} from './constants'

export const useApproveTextView = (
  enabledReservationLocations: ReservationLocation[],
  approvalTextEditorState: ApprovalTextEditorState,
) => {
  const isAllLocationsFullyManual = useMemo(
    () => enabledReservationLocations.every((location) => getIsLocationCompletelyManual(location)),
    [enabledReservationLocations],
  )

  const shouldShowAutoApproveText = getShouldShowAutoApproveText(
    approvalTextEditorState,
    isAllLocationsFullyManual,
  )

  return {shouldShowAutoApproveText}
}
