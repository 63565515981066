import {useEffect, useState} from 'react'

export const useDynamicImport = <T,>(
  importFn: () => Promise<{default: React.ComponentType<T>}>,
) => {
  const [Component, setComponent] = useState<React.ComponentType<T> | null>(null)

  useEffect(() => {
    importFn().then((module) => {
      setComponent(() => module.default)
    })
  }, [importFn])

  return Component
}
