import {StyleParamType, createStylesParams} from '@wix/tpa-settings'

export type LayoutStylesParams = {
  spaceBetweenTitleAndDescriptionStripLayout: StyleParamType.Number
  spaceBetweenTextAndFormStripLayout: StyleParamType.Number
  spaceBetweenTitleAndDescriptionPackedLayout: StyleParamType.Number
  spaceBetweenTextAndFormPackedLayout: StyleParamType.Number
  spaceBetweenFormAndButtonPackedLayout: StyleParamType.Number
  verticalPadding: StyleParamType.Number
  horizontalPadding: StyleParamType.Number
  buttonHorizontalPadding: StyleParamType.Number
}

export const layoutStylesParams = createStylesParams<LayoutStylesParams>({
  spaceBetweenTitleAndDescriptionStripLayout: {
    type: StyleParamType.Number,
    getDefaultValue: () => 40,
  },
  spaceBetweenTextAndFormStripLayout: {
    type: StyleParamType.Number,
    getDefaultValue: () => 80,
  },
  spaceBetweenTitleAndDescriptionPackedLayout: {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  },
  spaceBetweenTextAndFormPackedLayout: {
    type: StyleParamType.Number,
    getDefaultValue: () => 40,
  },
  spaceBetweenFormAndButtonPackedLayout: {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  },
  verticalPadding: {
    type: StyleParamType.Number,
    inheritDesktop: false,
    getDefaultValue: (params) => (params.isMobile ? 0 : 20),
  },
  horizontalPadding: {
    type: StyleParamType.Number,
    inheritDesktop: false,
    getDefaultValue: (params) => (params.isMobile ? 0 : 20),
  },
  buttonHorizontalPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  },
})
