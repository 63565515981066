import {
  tableReservationsCancelationSucceed,
  tableReservationsClickOnReservationsAttribute,
  tableReservationCreateReservationSucceed,
  tableReservationsUouStartOfReservationFunnelClickOnFindATable,
  tableReservationsUouClickOnCompleteReservation,
  tableReservationsUouClickOnReserveNow,
  tableReservationsUouStartOfReservationFunnelReservationPageWidgetIsLoaded,
  tableReservationsUouClickOnChangeReservationDetails,
  tableReservationsUouClickOnAddToCalendar,
} from '@wix/bi-logger-restaurants/v2'
import {tableReservationEditorDisplayTabSrc159Evid1642} from '@wix/bi-logger-restaurants-data/v2'
import {tableReservationCreateReservationSucceedParams} from '@wix/bi-logger-restaurants/v2/types'
import {
  Reservation,
  Source,
  Reservee,
} from '@wix/ambassador-table-reservations-v1-reservation/types'
import {
  ReservationLocation,
  CustomFieldDefinition,
} from '@wix/ambassador-table-reservations-v1-reservation-location/types'
import {VisitorLogger} from '@wix/yoshi-flow-editor/external-types/bi'

import {DisplaySettingsParams} from '../components/Reservations/Settings/Tabs/Display/settingsParams'

enum ViewMode {
  preview = 'preview_mode',
  liveSite = 'live_site_mode',
}

export enum PageType {
  page = 'page',
  widget = 'widget',
}

export enum Checkbox {
  check = 'check',
  uncheck = 'uncheck',
}

export enum FieldName {
  location = 'location',
  partySize = 'party_size',
  date = 'date',
  time = 'time',
}

export enum BiReservationType {
  reservation = 'reservation',
  walkIn = 'walk_in',
}

interface ReservationCreatedParams {
  isPreview: boolean
  reservation: Reservation
  reservationLocation?: ReservationLocation
  reservee: Reservee
  isCardTokenization: boolean
}

export const getLogger = (logger: VisitorLogger) => {
  const isLoaded = ({
    isAddOn,
    isPreview,
    pageType,
  }: {
    isAddOn: boolean
    isPreview: boolean
    pageType: PageType
  }) => {
    logger.report(
      tableReservationsUouStartOfReservationFunnelReservationPageWidgetIsLoaded({
        isAddOn,
        origin: isPreview ? ViewMode.preview : ViewMode.liveSite,
        type: pageType,
      }),
    )
  }

  const clickOnReserveNow = ({
    locationId,
    reservationLocationId,
    isPreview,
    partySize,
    requestedDate,
  }: {
    locationId?: string
    reservationLocationId: string
    isPreview: boolean
    partySize: number
    requestedDate: Date
  }) => {
    logger.report(
      tableReservationsUouClickOnReserveNow({
        locationId,
        origin: isPreview ? ViewMode.preview : ViewMode.liveSite,
        partySize,
        requestedDate: requestedDate.getTime(),
        reservationLocationId,
      }),
    )
  }

  const clickOnCompleteReservation = (
    {
      locationId,
      mailCheckbox,
      numInvaldFields,
      numValidFields,
      areaCode,
      isPreview,
      reservationLocationId,
    }: {
      locationId?: string | null
      reservationLocationId?: string | null
      mailCheckbox: boolean
      numInvaldFields: number
      numValidFields: number
      areaCode: string
      isPreview: boolean
    },
    reservation?: Reservation,
  ) => {
    logger.report(
      tableReservationsUouClickOnCompleteReservation({
        areaCode,
        contactId: reservation?.reservee?.contactId ?? undefined,
        dateTimeValue: reservation?.details?.startDate?.getTime(),
        locationId: locationId ?? undefined,
        mailCheckbox: mailCheckbox?.toString() ?? undefined,
        numInvaldFields,
        numValidFields,
        origin: isPreview ? ViewMode.preview : ViewMode.liveSite,
        partySize: reservation?.details?.partySize ?? undefined,
        reservationLocationId: reservationLocationId ?? undefined,
      }),
    )
  }

  const clickOnFindATable = ({
    datetime,
    isMultiLocation,
    locationId,
    isPreview,
    partySize,
    reservationLocationId,
  }: {
    datetime: Date
    isMultiLocation: boolean
    locationId?: string | null
    reservationLocationId: string
    isPreview: boolean
    partySize: number
  }) => {
    logger.report(
      tableReservationsUouStartOfReservationFunnelClickOnFindATable({
        datetime: datetime.getTime(),
        isAddOn: true,
        isMultiLocation,
        locationId: locationId ?? undefined,
        origin: isPreview ? ViewMode.preview : ViewMode.liveSite,
        partySize,
        reservationLocationId,
      }),
    )
  }

  const reservationCreated = (params: ReservationCreatedParams) => {
    logger.report(
      tableReservationCreateReservationSucceed({
        saveType: 'create',
        ...getParamsNew(params),
      }),
    )
  }

  const reservationCanceled = ({
    reservation,
    isPreview,
    locationId,
  }: {
    reservation: Reservation
    isPreview: boolean
    locationId?: string | null
  }) => {
    logger.report(
      tableReservationsCancelationSucceed({
        datetime: reservation.details?.startDate?.getTime() ?? undefined,
        locationId: locationId ?? undefined,
        origin: isPreview ? ViewMode.preview : ViewMode.liveSite,
        partySize: reservation.details?.partySize ?? undefined,
        reservationId: reservation.id ?? undefined,
        reservationLocationId: reservation.details?.reservationLocationId ?? undefined,
        reservationStatus: reservation.status,
      }),
    )
  }

  const clickOnReservationsAttributes = ({
    fieldName,
    isAddOn,
    isMultiLocation,
    isPreview,
    locationId,
    reservationLocationId,
  }: {
    fieldName: FieldName
    isAddOn: boolean
    isMultiLocation: boolean
    isPreview: boolean
    locationId?: string | null
    reservationLocationId?: string | null
  }) => {
    logger.report(
      tableReservationsClickOnReservationsAttribute({
        fieldName,
        isAddOn,
        isMultiLocation,
        locationId: locationId ?? undefined,
        origin: isPreview ? ViewMode.preview : ViewMode.liveSite,
        reservationLocationId: reservationLocationId ?? undefined,
      }),
    )
  }

  // for now only usable in Reservation
  const changedDisplaySetting = ({
    action,
    value,
    isAddon = false,
  }: {
    action: keyof DisplaySettingsParams
    value: string
    isAddon?: boolean
  }) => {
    logger.report(
      tableReservationEditorDisplayTabSrc159Evid1642({
        action,
        value,
        is_add_on: isAddon,
      }),
    )
  }

  const clickOnChangeReservationDetails = ({
    locationId,
    reservation,
    reservationLocationId,
    isPreview,
  }: {
    locationId?: string | null
    reservation: Reservation
    reservationLocationId?: string | null
    isPreview: boolean
  }) => {
    logger.report(
      tableReservationsUouClickOnChangeReservationDetails({
        locationId: locationId ?? undefined,
        origin: isPreview ? ViewMode.preview : ViewMode.liveSite,
        reservationId: reservation.id ?? undefined,
        reservationLocationId: reservationLocationId ?? undefined,
        reservationStatus: reservation.status === 'HELD' ? reservation.status : undefined,
      }),
    )
  }

  const clickOnAddToCalendar = ({
    locationId,
    reservation,
    reservationLocationId,
    isPreview,
    isSuccessful,
  }: {
    locationId?: string | null
    reservation: Reservation
    reservationLocationId?: string | null
    isPreview: boolean
    isSuccessful: boolean
  }) => {
    logger.report(
      tableReservationsUouClickOnAddToCalendar({
        locationId: locationId ?? undefined,
        origin: isPreview ? ViewMode.preview : ViewMode.liveSite,
        reservationId: reservation.id ?? undefined,
        reservationLocationId: reservationLocationId ?? undefined,
        isSuccessful,
      }),
    )
  }

  return {
    isLoaded,
    clickOnReserveNow,
    clickOnCompleteReservation,
    clickOnFindATable,
    reservationCreated,
    reservationCanceled,
    clickOnReservationsAttributes,
    clickOnChangeReservationDetails,
    changedDisplaySetting,
    clickOnAddToCalendar,
  }
}

const getParamsNew = ({
  isPreview,
  reservation,
  reservationLocation,
  reservee,
  isCardTokenization,
}: ReservationCreatedParams): tableReservationCreateReservationSucceedParams => {
  const createdDate = reservation?.createdDate
  const startDate = reservation?.details?.startDate
  const endDate = reservation?.details?.endDate

  const additionalDetails = reservation?.reservee?.customFields
    ? JSON.stringify(reservation.reservee.customFields)
    : undefined

  const reservationDuration =
    endDate && startDate ? endDate.getTime() - startDate.getTime() : undefined

  const reservationType = reservation?.source
    ? reservation.source === Source.WALK_IN
      ? BiReservationType.walkIn
      : BiReservationType.reservation
    : undefined

  return {
    additionalDetails,
    contactId: reservation?.reservedBy?.contactId ?? undefined,
    creationDate: createdDate?.getTime(),
    mailChecked: reservee?.marketingConsent ?? undefined,
    ...getNumAdditionalDetails(
      reservationLocation?.configuration?.reservationForm?.customFieldDefinitions ?? [],
      reservee?.customFields,
    ),
    ...getNumGuestDetail(reservee),
    origin: isPreview ? ViewMode.preview : ViewMode.liveSite,
    partySize: reservation?.details?.partySize ?? undefined,
    reservationId: reservation?.id ?? undefined,
    reservationDuration,
    statusCurrent: reservation.status,
    reservationLocationId: reservation?.details?.reservationLocationId ?? undefined,
    reservationStartDate: reservation?.details?.startDate?.getTime() ?? undefined,
    reservationType,
    paymentStatus: reservation?.paymentStatus,
    isCardTokenization,
  }
}

const getNumAdditionalDetails = (
  customFieldDefinitions: CustomFieldDefinition[],
  customFields: Reservee['customFields'],
) => {
  let numAdditionalDetailsEmpty: number | undefined
  let numAdditionalDetailsFull: number | undefined
  let numAdditionalDetailsMandatory: number | undefined

  customFieldDefinitions.forEach(({id, required}) => {
    const customFieldValue = id && customFields?.[id] ? customFields[id] : undefined

    if (customFieldValue) {
      numAdditionalDetailsFull = numAdditionalDetailsFull ? numAdditionalDetailsFull + 1 : 1
    } else {
      numAdditionalDetailsEmpty = numAdditionalDetailsEmpty ? numAdditionalDetailsEmpty + 1 : 1
    }

    if (required) {
      numAdditionalDetailsMandatory = numAdditionalDetailsMandatory
        ? numAdditionalDetailsMandatory++
        : 1
    }
  })

  return {
    numAdditionalDetailsEmpty,
    numAdditionalDetailsFull,
    numAdditionalDetailsMandatory,
  }
}

const getNumGuestDetail = (reservee?: Reservee) => {
  let numGuestDetailEmpty: number | undefined
  let numGuestDetailFull: number | undefined
  ;[reservee?.firstName, reservee?.lastName, reservee?.phone, reservee?.email].forEach((val) => {
    if (val) {
      numGuestDetailFull = numGuestDetailFull ? numGuestDetailFull + 1 : 1
    } else {
      numGuestDetailEmpty = numGuestDetailEmpty ? numGuestDetailEmpty + 1 : 1
    }
  })

  return {
    numGuestDetailEmpty,
    numGuestDetailFull,
  }
}
