import {
  StyleParamType,
  wixColorParam,
  wixFontParam,
  createStylesParams,
  getSettingsValues,
} from '@wix/tpa-settings'
import {TextFieldTheme} from 'wix-ui-tpa'

import {formSettingsParams} from './settingsParams'

export type FormBoxStylesParams = {
  formBoxFieldHeight: StyleParamType.Number
  formBoxFillColor: StyleParamType.Color
  formBoxBorderColor: StyleParamType.Color
  formBoxBorderWidth: StyleParamType.Number
  formBoxBorderRadius: StyleParamType.Number
  formBoxDropdownBackgroundColor: StyleParamType.Color
  formBoxSpaceBetweenFields: StyleParamType.Number
  formBoxLabelFont: StyleParamType.Font
  formBoxLabelColor: StyleParamType.Color
  formBoxTextFont: StyleParamType.Font
  formBoxTextColor: StyleParamType.Color
  formBoxSpaceBetweenFieldAndLabel: StyleParamType.Number
}

export const formBoxStylesParams = createStylesParams<FormBoxStylesParams>({
  formBoxFieldHeight: {
    type: StyleParamType.Number,
    getDefaultValue: () => 40,
  },
  formBoxFillColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 1),
  },
  formBoxBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: (params) => {
      const textFieldThemeSetting = getSettingsValues({}, formSettingsParams).textFieldTheme
      const isTextFieldBox = textFieldThemeSetting === TextFieldTheme.Box
      return wixColorParam('color-5', isTextFieldBox ? 0.6 : 1)(params)
    },
  },
  formBoxBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  formBoxBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  formBoxDropdownBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 1),
  },
  formBoxSpaceBetweenFields: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  formBoxLabelFont: {
    type: StyleParamType.Font,
    getDefaultValue: (params) =>
      wixFontParam('Body-M', {
        size: params.isMobile ? 14 : 16,
      })(params),
  },
  formBoxLabelColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  formBoxTextFont: {
    type: StyleParamType.Font,
    inheritDesktop: false,
    getDefaultValue: (params) =>
      wixFontParam('Body-M', {
        size: params.isMobile ? 14 : 16,
      })(params),
  },
  formBoxTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  formBoxSpaceBetweenFieldAndLabel: {
    type: StyleParamType.Number,
    getDefaultValue: () => 8,
  },
})

export type FormLineStylesParams = {
  formLineBackgroundColor: StyleParamType.Color
  formLineLineColor: StyleParamType.Color
  formLineBorderWidth: StyleParamType.Number
  formLineDropdownBackgroundColor: StyleParamType.Color
  formLineSpaceBetweenFields: StyleParamType.Number
  formLineLabelFont: StyleParamType.Font
  formLineLabelColor: StyleParamType.Color
  formLineTextFont: StyleParamType.Font
  formLineTextColor: StyleParamType.Color
  formLineSpaceBetweenFieldAndLabel: StyleParamType.Number
}

export const formLineStylesParams = createStylesParams<FormLineStylesParams>({
  formLineBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 0),
  },
  formLineLineColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 1),
  },
  formLineBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  formLineDropdownBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 1),
  },
  formLineSpaceBetweenFields: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  formLineLabelFont: {
    type: StyleParamType.Font,
    inheritDesktop: false,
    getDefaultValue: wixFontParam('Body-M', {size: 14}),
  },
  formLineLabelColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  formLineTextFont: {
    type: StyleParamType.Font,
    inheritDesktop: false,
    getDefaultValue: (params) =>
      wixFontParam('Body-M', {
        size: params.isMobile ? 14 : 16,
      })(params),
  },
  formLineTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  formLineSpaceBetweenFieldAndLabel: {
    type: StyleParamType.Number,
    getDefaultValue: () => 8,
  },
})
