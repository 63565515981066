import isAfter from 'date-fns/isAfter'
import setHours from 'date-fns/setHours'
import setMinutes from 'date-fns/setMinutes'
import eachMinuteOfInterval from 'date-fns/eachMinuteOfInterval'
import {TimeSlot, Status} from '@wix/ambassador-table-reservations-v1-time-slot/types'
import {SpecialHourPeriod} from '@wix/ambassador-table-reservations-v1-reservation-location/types'
import {DropdownOptionProps} from 'wix-ui-tpa/cssVars'
import {
  getHMFromShortTimeString,
  setHMToDate,
  Calendar,
  getDateWithUTCTime,
} from '@wix/table-reservations-lib/schedule'

import {getShortTime} from './dateString'
import {roundUpToNearestQuarterTime, roundDownToNearestQuarterTime} from './date'

export type TimeOption = DropdownOptionProps & {
  data: {startDate: Date; status: Status; hours: number; minutes: number}
}

export const generateTimeOptionsBasedOnDaySchedule = (
  date: Date,
  schedule: Calendar[string]['schedule'],
  regionalSettings?: string,
  specialEvents?: SpecialHourPeriod[],
): TimeOption[] => {
  let timeOptions: TimeOption[] = []
  const today = new Date()

  if (!date || !schedule) {
    return []
  }

  schedule.forEach(({open, close}) => {
    const [openHours, openMinutes] = getHMFromShortTimeString(open)
    const openDate = setHours(setMinutes(date, Number(openMinutes)), Number(openHours))
    const [closeHours, closeMinutes] = getHMFromShortTimeString(close)
    const closeDate = setHMToDate(date, closeHours, closeMinutes)

    if (openDate.getTime() >= closeDate.getTime()) {
      return
    }

    const timeIntervals = eachMinuteOfInterval(
      {
        start: roundDownToNearestQuarterTime(openDate),
        end: roundUpToNearestQuarterTime(closeDate),
      },
      {step: 15},
    ).filter((timeInterval) => {
      const timeIntervalTime = timeInterval.getTime()

      const isDuringOpenHours =
        openDate.getTime() <= timeIntervalTime && timeIntervalTime <= closeDate.getTime()

      const isStartOfClosedEvent = specialEvents
        ? specialEvents.some((event) => {
            const eventStart = getDateWithUTCTime(event.startDate!).getTime()
            return event.isClosed && timeIntervalTime === eventStart
          })
        : false

      return isDuringOpenHours && !isStartOfClosedEvent
    })

    const futureTimeIntervals = timeIntervals.filter((d: Date) => isAfter(d, today))

    const options: TimeOption[] = futureTimeIntervals.map((d: Date) => {
      const time = getShortTime(d, regionalSettings)

      return {
        id: time,
        value: time,
        data: {
          startDate: d,
          status: Status.AVAILABLE,
          hours: d.getHours(),
          minutes: d.getMinutes(),
        },
        isSelectable: true,
      }
    })

    timeOptions = [...timeOptions, ...options]
  })

  return timeOptions
}

export const getTimeOptions = ({
  timeSlots,
  regionalSettings,
}: {
  timeSlots: TimeSlot[]
  regionalSettings?: string
}): TimeOption[] => {
  return timeSlots
    .filter(({status}) => status === Status.AVAILABLE || status === Status.UNAVAILABLE)
    .map(({startDate, status}) => {
      const time = getShortTime(startDate!, regionalSettings)

      return {
        id: time,
        value: time,
        data: {
          startDate: startDate!,
          status: status!,
          hours: startDate!.getHours(),
          minutes: startDate!.getMinutes(),
        },
        isSelectable: true,
      }
    })
}

export const findTimeOption = (timeOptions: TimeOption[], date: Date) =>
  timeOptions.find(({data: {startDate}}) => startDate.getTime() === date.getTime())
