import {
  createStylesParams,
  createSettingsParams,
  StyleParamType,
  SettingsParamType,
} from '@wix/tpa-settings'

export type StylesParamsType<T extends Record<string, StyleParamType>> = ReturnType<
  typeof createStylesParams<T>
>
export type SettingsParamsType<T extends Record<string, SettingsParamType>> = ReturnType<
  typeof createSettingsParams<T>
>

export enum BreadcrumbsAlignment {
  Default = 'default',
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export enum TextAlignment {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export enum ButtonAlignment {
  Left = 'left',
  Center = 'center',
  Right = 'right',
  Stretch = 'Stretch',
}

export enum FormLayout {
  Packed = 'packed',
  Strip = 'strip',
}
